import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [ 'select' ]

  selectTargetConnected(el) {
    console.log('organisatiekiezer connected');
    new TomSelect(el, {
      valueField: 'id',
      labelField: 'naam',
      searchField: [],
      load: function(query, callback) {
        let url='/organisaties.json?q='+encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(json => {
            this.clearOptions();
            callback(json.items);
          }).catch(()=>{
            callback();
          });
      },
      render: {
        no_results: function(item, escape) {
          return '<div>Geen resultaten</div>';
        },
      }
      });
  }

  selectTargetDisconnected(el) {
    el?.tomselect?.destroy()
  }
}
