import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element, {})
   }

  handleSubmit(event) {
    if (event.detail.success) {
      this.close()
    }
  }

  close() {
    this.modal.hide()
  }
}
