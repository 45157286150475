import { Controller } from "@hotwired/stimulus"
import { get, destroy } from '@rails/request.js'

export default class extends Controller {
  static values = {
    frame: String,
    button: String,
    url: { type: String, default: '' },
    method: { type: String, default: 'get' }
  }

  connect() {
    console.log('resource connected');
  }

  emptyFrame() {
    const frame = document.querySelector(this.frameValue)
    if (frame) {
      frame.innerHTML = ''
    }
  }

  redrawDeleteButton(event) {
    event.preventDefault()
    const button = document.querySelector(this.buttonValue)
    if (button) {
      button.setAttribute('data-resource-url-value', this.urlValue)
    }
  }

  delete(event) {
    event.preventDefault()
    this.emptyFrame()
    this.processResponse(this.methodValue, this.urlValue)
  }

  new(event) {
    event.preventDefault()
    this.emptyFrame()
    this.processResponse(this.methodValue, this.urlValue)
  }

  edit(event) {
    event.preventDefault()
    this.emptyFrame()
    this.processResponse(this.methodValue, this.urlValue)
  }

  async processResponse(method, url) {
    const methodMap = {
      get,
      destroy
    }

    if (typeof methodMap[method] !== "function") {
      console.error(`Onbekende ${method}.`)
      return
    }
    const response = await methodMap[method](this.urlValue,
      {
        responseKind: 'turbo-stream',
      }
    )
    if (!response.ok) {
      console.error('Er gaat iets fout.')
      return
    }
  }

}
