import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.monitorEdits()
  }

  monitorEdits() {
    let self = this
    document
      .querySelectorAll('form')
      .forEach(function(formelement, index) {
        formelement
          .querySelectorAll('input, select, textarea')
          .forEach(function(element, index) {
            element.addEventListener('change', (event) => self.registerMarkDirtyOnChange(event.target))
          })
      })
  }

  registerMarkDirtyOnChange(element) {
    // ergens boven het form zit een div.tab-pane.
    // Pak diens id, schroef 'tab-' eraf, zet er tabs-link- voor en je hebt het tabje
    let tabname = element.form.closest('.tab-pane').id.replace('tab-', '')
    document.querySelector('a#tabs-link-'+tabname+' span[data-dirty]')
      .classList.remove('d-none')
  }

  deregisterMarkDirtyOnChange(form) {
    let tabname = form.closest('.tab-pane').id.replace('tab-', '')
    document.querySelector('a#tabs-link-'+tabname+' span[data-dirty]')
      .classList.add('d-none')
  }

  handleSubmit(event) {
    if (event.detail.success) {
      this.deregisterMarkDirtyOnChange(event.target)
    }
  }

  removeMarkOnCancel(event) {
    const form = event.target.closest('form')
    if (form) {
      this.deregisterMarkDirtyOnChange(form)
    }
  }

}
