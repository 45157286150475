import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log('auteur connected')
  }

  set_initialen(event) {
    document.getElementById('auteur_initialen').value = this.name2initials(event.target.value)
  }

  set_alfabetisering(event) {
    document.getElementById('auteur_achternaam_alfabetisering').value = event.target.value
  }

  name2initials(str) {
    if (typeof str == 'undefined') {
      return ''
    }
    if (str.length < 1) {
      return ''
    }
    return str[0].toUpperCase() + '.'
  }

}
