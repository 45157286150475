import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const activeTab = document.querySelector('.tabs-link.active')
    if (activeTab) {
      const text = activeTab.id.replace('tabs-link-', '')
      document.getElementById('in_pagina_sub_titel')
      .textContent = text.substring(0,1).toUpperCase() + text.substring(1)

    }
  }

  zet_in_pagina_sub_titel(event) {
    document.getElementById('in_pagina_sub_titel')
    .textContent = event.params.subtitel
  }
}
