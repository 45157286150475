import { Controller } from "@hotwired/stimulus"
import { get, destroy } from '@rails/request.js'

export default class extends Controller {
  emptyFrame() {
    const frame = document.querySelector('#krs_contactpersoon')
    if (frame) {
      frame.innerHTML = ''
    }
  }

  redrawDeleteButton(event) {
    event.preventDefault()
    const button = document.querySelector('#verwijder_contactpersoon_button')
    if (button) {
      button.setAttribute('data-contactpersoon-url-param', event.params.url)
    }
  }

  delete(event) {
    event.preventDefault()
    this.emptyFrame()
    this.processResponse(event.params.url)
  }

  new(event) {
    event.preventDefault()
    this.emptyFrame()
    this.processResponse(event.params.url)
  }

  edit(event) {
    event.preventDefault()
    this.emptyFrame()
    this.processResponse(event.params.url)
  }

  async processResponse(url) {
    const response = await get(event.params.url,
      {
        responseKind: 'turbo-stream',
      }
    )
    if (!response.ok) {
      console.error('Er gaat iets fout.')
      return
    }
    return response
  }

}
