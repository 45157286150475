import { Controller } from "@hotwired/stimulus"
import { get, post, destroy } from '@rails/request.js'

export default class extends Controller {

  static values = {
    segment: String
  }

  connect() {
    console.log('filters connected')
    this.dispatch('reload', { detail: {}})
  }

  async remove(event) {
    event.preventDefault()
    let filter = event.currentTarget
    await destroy('/krs/filters/'+filter.id+'?segment='+this.segmentValue)
    // moet ook "de tabel" hertekenen. Hoe vinden we de verantwoordelijke daarvoor?
    this.dispatch('reload', { detail: {}})
  }

}
