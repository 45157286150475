import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    lowerId: String,
    upperId: String
  }

  connect() {
    this.modal = new bootstrap.Modal(document.getElementById(this.upperIdValue, {}))
   }

  handleSubmit(event) {
    if (event.detail.success) {
      this.close()
      this.openLowerModal()
    }
  }

  close() {
    this.modal.hide()
  }

  openLowerModal() {
    this.lowerModal = new bootstrap.Modal(document.getElementById(this.lowerIdValue))
    this.lowerModal.show()
  }
}
