import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['month', 'year'];

  connect() {
    console.log('kalender connected');
  }

  update(event) {
    let date = this.yearTarget.value + '-' + this.monthTarget.value + '-1'
    self.location="?start_date="+date
  }

}
