import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";


// Connects to data-controller="tom-select"
export default class extends Controller {
  static targets = [ 'select' ]

  selectTargetConnected(el) {
    new TomSelect(el, {});
  }

  selectTargetDisconnected(el) {
    el?.tomselect?.destroy()
  }
}
